<template>
  <footer class="info-footer">

    <router-link to="/booking/0" class="plus-btn-link-ssssss" v-if="show">
      {{ $t('Calendar') }}
    </router-link>

    <div class="container-fluid">
      <!-- <div class="footer-upper">
        <div class="footer-newsletter row">
          <div class="footer-newsletter__content col-lg-6 offset-lg-3">
            <h4>Newsletter abonnieren</h4>

            <div class="footer-newsletter__content--intro">
              <p>
                Need a break from everyday life? The Quellenhotel Heiltherme Bad
                Waltersdorf newsletter will inspire you.
              </p>
              <div>
                <input
                  type="email"
                  name="mailholder"
                  placeholder="Ihre E-Mail-Adresse"
                />
                <a href="#" class="button blue">Anmelden</a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="footer-lower container">
        <div>
          <!-- <h2 class="font-design">
            wellbein<span class="last-letter">g</span>
          </h2> -->
        </div>
        <div class="contact">
          <a href="mailto:info@kleopatramassage-beauty.com">
            <img
              src="../assets/mail_736506bf.svg"
              alt="eMail Icon"
              data-lazy-src="../assets/mail_736506bf.svg"
              data-ll-status="loaded"
              class="entered lazyloaded"
              width="auto"
              height="45"
            /><noscript
              ><img
                src="../assets/mail_736506bf.svg"
                alt="eMail Icon"
                height="45"
                width="auto"
            /></noscript>
            info@kleopatramassage-beauty.com
          </a>
          <a href="tel:+436601021010" style="font-size: 1rem !important; 
  font-family: Arial, Helvetica, sans-serif;">
            <img
              src="../assets/phone_bead07a6.svg"
              alt="Phone Icon"
              data-lazy-src="../assets/phone_bead07a6.svg"
              data-ll-status="loaded"
              class="entered lazyloaded"
              width="auto"
              height="45"
              style="max-height: 2em;"
            /><noscript
              ><img
                src="../assets/phone_bead07a6.svg"
                alt="Phone Icon"
                height="45"
                width="auto"
            /></noscript>
            <!-- +43(0)55723888 562
            <br />
            +43(0)660 6128341 -->
            +43(0)660 10 2 10 10
          </a>
        </div>
        <div class="row align-items-center information">
          <div class="col-12 col-lg-4 address">
            <h4>{{ $t('You can find us here') }}</h4>
            <p>
              Messestraße 1, 6850 Dornbirn.
              <br />
              Im Panoramahaus Dornbirn.
            </p>
            <router-link to="/google-map" target="" class="button">
              <span>{{ $t('Getting there') }}</span>
            </router-link>
          </div>
          <div class="col-12 col-lg-4 request">
            <h4>{{ $t('Any Questions?') }}</h4>
            <p>
              {{ $t('We are happy') }} <br />
              {{ $t('to help you!') }}
            </p>
            <router-link to="/contactUs" class="button login">{{
              $t('Contact us')
            }}</router-link>
          </div>
          <div class="col-12 col-lg-4 agb-und-co">
            <div class="menu-footer-en-container">
              <h4>{{ $t('Links') }}</h4>

              <ul id="menu-footer-en" class="nav">
                <li v-for="item of list" :key="item.id">
                  <router-link
                    :to="'/page/' + item.slug"
                    class="course-overlay"
                  >
                    {{ $getByLang(item.name) }}</router-link
                  >
                </li>
                <li>
                  <router-link :to="'/faqs'" class="course-overlay">
                    {{ $t('Faqs') }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="socials">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                class="social-link"
              >
                <img
                  src="../assets/fb-social-icon.svg"
                  alt="Facebook"
                  data-lazy-src="../assets/fb-social-icon.svg"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  width="35.715"
                  height="35.715"
                /><noscript
                  ><img
                    width="35.715"
                    height="35.715"
                    src="../assets/fb-social-icon.svg"
                    alt="Facebook"
                /></noscript>
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                class="social-link"
              >
                <img
                  src="../assets/insta-social-icon.svg"
                  alt="Instagram"
                  data-lazy-src="../assets/insta-social-icon.svg"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  width="35.483"
                  height="35.483"
                /><noscript
                  ><img
                    width="35.483"
                    height="35.483"
                    src="../assets/insta-social-icon.svg"
                    alt="Instagram"
                /></noscript>
              </a>
              <a
                href="https://www.pinterest.de/"
                target="_blank"
                class="social-link"
              >
                <img
                  src="../assets/pinterest-social-icon.svg"
                  alt="Pinterest"
                  data-lazy-src="../assets/pinterest-social-icon.svg"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  width="35.655"
                  height="35.655"
                /><noscript
                  ><img
                    width="35.655"
                    height="35.655"
                    src="../assets/pinterest-social-icon.svg"
                    alt="Pinterest"
                /></noscript>
              </a>
              <a
                href="https://www.youtube.com/channel/"
                target="_blank"
                class="social-link"
              >
                <img
                  src="../assets/yt-social-icon.svg"
                  alt="Youtube"
                  data-lazy-src="../assets/yt-social-icon.svg"
                  data-ll-status="loaded"
                  class="entered lazyloaded"
                  width="35.715"
                  height="35.715"
                /><noscript
                  ><img
                    width="35.715"
                    height="35.715"
                    src="../assets/yt-social-icon.svg"
                    alt="Youtube"
                /></noscript>
              </a>
            </div>
          </div>
        </div>
        <div class="partner">
          <a href="#" target="_blank">
            <img
              class="lazyload"
              data-src="../assets/heiltherme_steiermark_logo_d52f165e.svg"
              src="../assets/heiltherme_steiermark_logo_d52f165e.svg"
              data-loaded="true"
            />
          </a>
          <a href="#" target="_blank">
            <img
              class="lazyload"
              data-src="../assets/heiltherme_oesterreich_logo_765c53b3.svg"
              src="../assets/heiltherme_oesterreich_logo_765c53b3.svg"
              data-loaded="true"
            />
          </a>
          <a href="#" target="_blank">
            <img
              class="lazyload"
              data-src="../assets/heiltherme_thermenland_logo_134b3525.svg"
              src="../assets/heiltherme_thermenland_logo_134b3525.svg"
              data-loaded="true"
            />
          </a>
          <a href="#" target="_blank">
            <img
              class="lazyload"
              data-src="../assets/heiltherme_therme_plus_logo_0c795564.png"
              src="../assets/heiltherme_therme_plus_logo_0c795564.png"
              data-loaded="true"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
 

</template>

<script>
export default {
  name: 'app-footer',
  data() {
    return {
      list: [],
      show: true,
    };
  },
  created() {
    this.$http.get(`page?typePage=footer`).then(
      (res) => {
        this.list = res.data;
      },
      (err) => {
        console.log(err);
      },
    );

    if (this.$route.path.includes('/booking/')) {
      this.show = false;
    }
  },
  watch: {
    '$route.path': function (newPath) {
      if (newPath.includes('/booking/')) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.plus-btn-link-ssssss {
  color: #fff;
  font-weight: 600;
  position: fixed;
  bottom: 11%;
  left: 4%;
  z-index: 9999;

  height: 50px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #af002d ;
  text-decoration: none !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  border: 1px solid #af002d !important;
  border-radius: 25px !important;
  padding: 0 35px !important;
  background-color: #f5f5f5 ;
}
.plus-btn-link-ssssss:hover {
  background-color: #af002d !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  .plus-btn-link-ssssss {
    bottom: 2% !important;
    left: 2% !important;
  }
}


</style>
